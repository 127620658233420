import React, { Component } from "react";
import "./Authentication.css";
import { Link, Redirect } from "react-router-dom";
import config from "../../config";
import { MdClose } from "react-icons/md";
import { FaExclamationCircle } from "react-icons/fa";
import Modal from "react-modal";
import FilledInput from "@mui/material/FilledInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
Modal.setAppElement("body");

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      passwordTips: "",
      confirmPassword: "",
      iAgree: false,
      invalidLoginTriggered: false,
      redirect: false,
      loading: false,
      emailAlreadyInUse: false,
      showPassword: false,
    };
  }

  validateForm() {
    const passwordTip = this.checkPasswordStrength(this.state.password);
    if (this.state.email.length === 0) {
      this.setState({
        invalidLoginTriggered: "Please provide an email.",
      });
    } else if (this.state.password.length === 0) {
      this.setState({
        invalidLoginTriggered: "Please provide a password.",
      });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        invalidLoginTriggered: "Passwords do not match.",
      });
    } else if (!this.state.iAgree) {
      this.setState({
        invalidLoginTriggered: "Please agree to the Terms of Service and Privacy Policy.",
      });
    } else if (passwordTip.length > 0) {
      this.setState({
        invalidLoginTriggered: passwordTip,
      });
    } else {
      return true;
    }

    return false; //If not in else, then it's failed
  }
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    const formValidated = this.validateForm();

    if (formValidated) {
      this.setState({ isLoading: true });
      let url = config.backendServer + "registration/";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password1: this.state.password,
          password2: this.state.password,
          email: this.state.email,
        }),
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          response.text().then((text) => {
            this.setState({ redirect: true, emailProblem: text, password: "", confirmPassword: "" });
          });
        } else if (response.status === 409) {
          this.setState({
            emailAlreadyInUse: true,
            email: "",
            password: "",
            confirmPassword: "",
          });
        } else {
          alert("Problem with system. Please refresh and try again later.");
        }
      });
    }
  };

  handleCheckboxChange = (event) => {
    this.setState({
      [event.target.id]: event.target.checked,
    });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  checkPasswordStrength = (password) => {
    // Initialize variables

    var tips = "";

    // Check password length
    if (password.length < 8) {
      tips += "Make the password longer. ";
    }

    // Check for mixed case
    if (password.match(/[a-z]/) || password.match(/[A-Z]/)) {
    } else {
      tips += "Use alphanumeric. ";
    }

    return tips;
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.emailAlreadyInUse}
          onRequestClose={() => this.setState({ emailAlreadyInUse: false })}
          contentLabel={"Email is already in use."}
          className={"auth-error-modal"}
        >
          <button onClick={() => this.setState({ emailAlreadyInUse: false })} className={"auth-modal-close"}>
            <MdClose />
          </button>
          <div className={"auth-error-icon"}>
            <FaExclamationCircle />
          </div>
          <div className={"auth-error-main-text"}>Sorry</div>
          <div className={"auth-error-sub-text"}>This email is already in use. Please try another email.</div>
        </Modal>
        {this.state.redirect && (
          <Redirect
            to={{
              pathname: "/signupsuccess",
              state: { emailProblem: this.state.emailProblem },
            }}
          />
        )}
        <h2 className={"auth-page-header"}>Create account</h2>

        <form onSubmit={this.handleSubmit}>
          <div className="auth-form-field">
            <label>Email</label>
            <input
              tabindex="1"
              type={"email"}
              required={true}
              id={"email"}
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="input your email"
            />
          </div>
          <div className="auth-form-field">
            <label>Password</label>

            <FilledInput
              id="password"
              tabindex="1"
              type={this.state.showPassword ? "text" : "password"}
              style={{ width: "100%", border: "1px solid #ddd", backgroundColor: "white" }}
              value={this.state.password}
              onChange={this.handleChange}
              placeholder="input your password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    // onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className="auth-form-field">
            <label>Confirm Password</label>

            <FilledInput
              id="confirmPassword"
              tabindex="2"
              type={this.state.showPassword ? "text" : "password"}
              style={{ width: "100%", border: "1px solid #ddd", backgroundColor: "white" }}
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              placeholder="input your password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    // onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className={"auth-options-section clearfix"}>
            <label className="auth-checkbox-field">
              I agree to the{" "}
              <a target={"blank"} href={"https://dreame.com.au/pages/terms-conditions"}>
                Terms of Service
              </a>{" "}
              and{" "}
              <a target={"blank"} href={"https://dreame.com.au/policies/privacy-policy"}>
                Privacy Policy.
              </a>
              <input tabindex="4" type="checkbox" id="iAgree" onChange={this.handleCheckboxChange} checked={this.state.iAgree} />
              <span className="checkmark" />
            </label>
          </div>
          {this.state.invalidLoginTriggered && (
            <div className={"incorrectAuthenticationText "}>
              <small>{this.state.invalidLoginTriggered}</small>
            </div>
          )}
          <button className="k-btn k-btn-primary-full" type="submit">
            Sign up
          </button>
          <div className={"auth-extra-link-section"}>
            Already have an account? <Link to={"/login"}>Login</Link>
          </div>
        </form>
      </div>
    );
  }
}
