import React, { Component } from "react";
import "./Authentication.css";
import config from "../../config";
import { Link } from "react-router-dom";
import LoadingPage from "../../components/Loading/Loading";
import axios from "axios";

export default class SignupVerified extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isVerified: true,
      emailVerification: undefined,
    };
  }
  componentDidMount() {
    this.checkToken();
  }

  checkToken() {
    const token = this.props.computedMatch.params.token;
    const url = config.backendServer + `registration/verify-email/`;
    let formData = {
      key: token,
    };
    console.log(url);
    axios
      .post(url, formData, {})
      .then((res) => {
        if (res.data) {
          this.setState({
            emailVerification: res.data,
            isLoading: false,
          });
          this.props.handleToken(res.data.token);
          this.props.handleUid(res.data.uid);
          console.log(res.data);
        }
      })
      .catch((error) => {
        window.location.href = "/";
        // Handle any errors that occurred during the fetch request
        console.error("Error:", error);
        this.setState({
          isLoading: false,
          isVerified: false,
        });
      });
  }

  render() {
    const token = this.props.computedMatch.params.token;
    if (this.state.isLoading) {
      return <LoadingPage small={true} />;
    } else if (this.state.emailVerification.verified) {
      return (
        <div>
          <h2 className={"auth-page-header"}>Account Verified</h2>
          <div className={"auth-page-info"}>
            {`Your account is ready to be used. Please click the button
            below.`}
          </div>
          {this.state.emailVerification.set_password ? (
            <Link className="k-btn k-btn-primary-full" to={"/login"}>
              Login
            </Link>
          ) : (
            <Link className="k-btn k-btn-primary-full" to={"/createpassword/" + token}>
              Setup Password
            </Link>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h2 className={"auth-page-header"}>Verification Fail</h2>
          <div className={"auth-page-info"}>Something went wrong. Contact Admin</div>
          <Link className="k-btn k-btn-primary-full" to={"/"}>
            Home
          </Link>
        </div>
      );
    }
  }
}
