import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";
import ShippingLabel from "../ShippingLabel/ShippingLabel";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import "../../common.css";
import "./Table.css";
export default class TicketTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: null,
    };
  }

  render() {
    return (
      <div>
        {this.props.tickets.length > 0 && (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>Ticket Number</TableCell>
                <TableCell>Warranty Status</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Last Update Date</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.tickets
                .sort((a, b) => (b.last_activity_date - a.last_activity_date ? 1 : -1))
                .map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.ticket_number}</TableCell>
                    <TableCell> {ticket.ticket_type.toUpperCase() === "OUT_OF_WARRANTY" ? " Out of Warranty" : " In Warranty"}</TableCell>
                    <TableCell>{moment(ticket.created).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{moment(ticket.last_activity_date).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{ticket.status}</TableCell>
                    <TableCell>
                      {ticket.status.includes("Pending Return Label to be Sent") && (
                        <button
                          className="k-btn k-btn-primary"
                          onClick={() => {
                            this.setState({ showShipping: true, ticket: ticket });
                          }}
                        >
                          Shipping Label
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

            </TableBody>
          </TableContainer>
        )}
        <Modal isOpen={this.state.showShipping} contentLabel={"Export Report"} className={"fullpage-modal"}>
          <ShippingLabel
            protectedFetch={this.props.protectedFetch}
            userDevice={this.state.ticket?.user_device}
            // disableInput={!!this.props.details.transdirect_status}
            handleCloseModal={() => this.setState({ showShipping: false })}
            ticketId={this.state.ticket?.id}
            retrieveWarranty={() => this.props.retrieveWarranty(this.state.ticket?.id)}
          />
        </Modal>
      </div>
    );
  }
}
