import React, { Component } from "react";
import "./Authentication.css";
import { Redirect } from "react-router-dom";
import config from "../../config";
import FilledInput from "@mui/material/FilledInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      password: "",
      confirmPassword: "",
      invalidLoginTriggered: false,
      redirect: false,
    };
  }

  validateForm() {
    if (this.state.password.length === 0) {
      this.setState({
        invalidLoginTriggered: "Please provide a password.",
      });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        invalidLoginTriggered: "Passwords do not match.",
      });
    } else {
      return true;
    }

    return false; //If not in else, then it's failed
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const formValidated = this.validateForm();

    if (formValidated) {
      this.setState({ isLoading: true });
      let url = config.backendServer + "password/reset/confirm/";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: this.state.password,
          token: this.props.computedMatch.params.token,
        }),
      }).then((response) => {
        if (response.status === 200) {
          this.setState({ redirect: true });
        } else {
          alert("Problem with system. Please close window and try again later.");
        }
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/resetpasswordsuccess"} />;
    }
    return (
      <div>
        <h2 className={"auth-page-header"}>Reset Password</h2>
        {this.state.invalidLoginTriggered && <div className={"incorrectAuthenticationText"}>{this.state.invalidLoginTriggered}</div>}
        <form onSubmit={this.handleSubmit}>
          <div className="auth-form-field">
            <label>New Password</label>
            <FilledInput
              id="password"
              tabindex="2"
              type={this.state.showPassword ? "text" : "password"}
              style={{ width: "100%", border: "1px solid #ddd", backgroundColor: "white" }}
              value={this.state.password}
              onChange={this.handleChange}
              placeholder="input your password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className="auth-form-field">
            <label>Confirm New Password</label>
            <FilledInput
              id="confirmPassword"
              tabindex="2"
              type={this.state.showPassword ? "text" : "password"}
              style={{ width: "100%", border: "1px solid #ddd", backgroundColor: "white" }}
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              placeholder="input your password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <button className="k-btn k-btn-primary-full" type="submit">
            Reset
          </button>
        </form>
      </div>
    );
  }
}
