import React, { Component } from "react";
import "./Authentication.css";
import { Link, Redirect } from "react-router-dom";
import LoadingPage from "../../components/Loading/Loading";
import config from "../../config";
import axios from "axios";
import FilledInput from "@mui/material/FilledInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      rememberMe: false,
      invalidLoginTriggered: false,
      redirect: false,
      showPassword: false,
    };
  }

  validateForm() {
    if (this.state.email.length === 0) {
      this.setState({
        invalidLoginTriggered: "Please provide an email.",
      });
    } else if (this.state.password.length === 0) {
      this.setState({
        invalidLoginTriggered: "Please provide a password.",
      });
    } else {
      return true;
    }

    return false; //If not in else, then it's failed
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleCheckboxChange = (event) => {
    this.setState({
      [event.target.id]: event.target.checked,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const formValidated = this.validateForm();

    if (formValidated) {
      this.setState({ isLoading: true });

      let url = config.backendServer + "login/";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: this.state.password,
          email: this.state.email,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.text();
            // return <Redirect to={"/"} />;
          } else if (response.status === 409) {
            this.setState({
              isLoading: false,
              invalidLoginTriggered: "Email used does not exist.",
              email: "",
              password: "",
            });
          } else if (response.status === 400) {
            this.setState({
              isLoading: false,
              invalidLoginTriggered: "You have entered an invalid username or password.",
            });
          } else if (response.status === 403) {
            this.setState({
              isLoading: false,
              invalidLoginTriggered: "email not verified",
              password: "",
            });
            return <Redirect to={"/needsverification"} />;
          } else {
            this.setState({
              isLoading: false,
              invalidLoginTriggered: "Problem with the system, try again later.",
              email: "",
              password: "",
            });
          }
        })
        .then((text) => {
          if (text) {
            // All fails should be handled in above function
            this.props.onLogin(text, this.state.rememberMe, () => {
              this.setState({ redirect: true, isLoading: false });
            });
          }
        });
    }
  };

  handleResendEmail = async () => {
    this.setState({
      isLoading: true,
    });
    let formData = {
      email: this.state.email,
    };
    axios
      .post(config.backendServer + "registration/resend-email/", formData, {})
      .then((response) => {
        if (response.data.detail === "ok") {
          this.setState({
            isLoading: false,
            invalidLoginTriggered: `Email verification send to ${this.state.email}.`,
            email: "",
            password: "",
          });
        }
      })
      .catch((error) => {
        // Handle the error or show an error message
        alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage small={true} />;
    } else if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div>
        <h2 className={"auth-page-header"}>Login</h2>

        {this.state.invalidLoginTriggered && (
          <div className={"incorrectAuthenticationText"}>
            {this.state.invalidLoginTriggered === "email not verified" ? (
              <p>
                Click{" "}
                <span onClick={this.handleResendEmail} style={{ cursor: "pointer", color: "blue" }}>
                  here{" "}
                </span>{" "}
                to verify your email address.
              </p>
            ) : (
              this.state.invalidLoginTriggered
            )}
          </div>
        )}
        <form onSubmit={this.handleSubmit}>
          <div className="auth-form-field">
            <label>Email</label>
            <input type={"email"} required={true} id={"email"} value={this.state.email} onChange={this.handleChange} placeholder="input your email" />
          </div>

          <FilledInput
            id="password"
            type={this.state.showPassword ? "text" : "password"}
            style={{ width: "100%", border: "1px solid #ddd", backgroundColor: "white" }}
            value={this.state.password}
            onChange={this.handleChange}
            placeholder="input your password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                  edge="end"
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <div className={"auth-options-section clearfix"}>
            <Link id="loginForgotPassword" to={"/forgotpassword"}>
              Forgot Password
            </Link>
          </div>
          <button className="k-btn k-btn-primary-full" type="submit">
            Login
          </button>
          <div className={"auth-extra-link-section"}>
            Don't have an account? <Link to={"/signup"}>Sign up now!</Link>
          </div>
        </form>
      </div>
    );
  }
}
